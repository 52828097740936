exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-klarna-success-js": () => import("./../../../src/pages/klarna-success.js" /* webpackChunkName: "component---src-pages-klarna-success-js" */),
  "component---src-pages-pay-order-js": () => import("./../../../src/pages/pay-order.js" /* webpackChunkName: "component---src-pages-pay-order-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vipps-success-js": () => import("./../../../src/pages/vipps-success.js" /* webpackChunkName: "component---src-pages-vipps-success-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-favourites-js": () => import("./../../../src/templates/favourites.js" /* webpackChunkName: "component---src-templates-favourites-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-search-products-js": () => import("./../../../src/templates/searchProducts.js" /* webpackChunkName: "component---src-templates-search-products-js" */),
  "component---src-templates-sharedcart-js": () => import("./../../../src/templates/sharedcart.js" /* webpackChunkName: "component---src-templates-sharedcart-js" */)
}

